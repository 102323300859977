import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { cx } from 'classix';
import { Entry } from 'contentful';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeaturePageModel } from 'repos/featurePage';
import { IntegrationPageModel } from 'repos/integrationPage';
import { UseCases } from 'repos/solutionPage';

import { getLinks } from 'utils/links';

import LocaleSelector from '../localeSelector';
import CloseIcon from '../svgs/close';
import MenuIcon from '../svgs/menu';
import Signature from '../svgs/signature';
import { Column } from './NavButtonWithTwoColumnsMenu';

type Props = {
  color: 'light' | 'dark';
  sticky?: boolean;
  overridePath?: string;
  menuParams: {
    useCases: Entry<UseCases>[];
    features: Entry<FeaturePageModel>[];
    integrations: Entry<IntegrationPageModel>[];
  };
};

type MenuProps = {
  color: 'light' | 'dark';
  overridePath?: string;
};

export default function Header({
  color = 'dark',
  sticky = false,
  overridePath,
  menuParams,
}: Props) {
  const router = useRouter();
  const { t } = useTranslation();

  const {
    platformButtonContent,
    solutionsButtonContent,
    resourcesButtonContent,
    companyButtonContent,
  } = getLinks({
    router,
    t,
    menuParams,
  });

  const [isOpened, setIsOpened] = useState(false);

  const platformNavIsSelected =
    platformButtonContent.firstColumn.isSelected ||
    platformButtonContent.secondColumn.isSelected ||
    platformButtonContent.overview.isSelected ||
    [
      ...platformButtonContent.firstColumn.items,
      ...platformButtonContent.secondColumn.items,
    ].some((item) => item.isSelected);

  const solutionNavIsSelected =
    solutionsButtonContent.firstColumn.isSelected ||
    solutionsButtonContent.secondColumn.isSelected ||
    solutionsButtonContent.overview.isSelected ||
    [
      ...solutionsButtonContent.firstColumn.items,
      ...solutionsButtonContent.secondColumn.items,
    ].some((item) => item.isSelected);

  return (
    <header
      className={cx(
        `top-0 z-40 w-full transition-colors`,
        sticky ? 'sticky' : 'fixed',
        color === 'dark' ? 'text-secondary' : 'text-white',
        color === 'dark' && 'bg-white',
        color === 'light' && 'bg-secondary'
      )}
    >
      <div
        className={cx(
          `border-b`,
          'border-transparent',
          color === 'dark' && 'border-gray-200',
          color === 'light' && 'border-gray-700'
        )}
      >
        <div className="mx-auto flex max-w-7xl items-center justify-between px-6 py-4 lg:py-0">
          <Link href="/">
            <a aria-label={t('home')}>
              <Signature
                color={color === 'dark' ? '#282828' : '#ffffff'}
                height={32}
                locale={router.locale}
              />
            </a>
          </Link>
          <div className="lg:hidden">
            <button
              className="p-2"
              onClick={() => setIsOpened(!isOpened)}
              aria-label="Menu"
            >
              {isOpened ? <CloseIcon width={24} /> : <MenuIcon width={24} />}
            </button>
          </div>
          <div className="hidden items-center lg:flex">
            <NavigationMenu.Root className="relative z-[1] flex justify-center rounded-none">
              <NavigationMenu.List className="flex">
                <NavItemMultiColumn
                  title={platformButtonContent.navTitle}
                  isSelected={platformNavIsSelected}
                  firstColumn={platformButtonContent.firstColumn}
                  secondColumn={platformButtonContent.secondColumn}
                  overview={platformButtonContent.overview}
                />
                <NavItemMultiColumn
                  id="solutions"
                  title={solutionsButtonContent.navTitle}
                  isSelected={solutionNavIsSelected}
                  firstColumn={solutionsButtonContent.firstColumn}
                  secondColumn={solutionsButtonContent.secondColumn}
                  overview={solutionsButtonContent.overview}
                />
              </NavigationMenu.List>

              <div className="perspective-[2000px] absolute top-full left-32 flex w-full justify-center">
                <NavigationMenu.Viewport className="relative h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center]  bg-white  sm:w-[var(--radix-navigation-menu-viewport-width)] rounded-none" />
              </div>
            </NavigationMenu.Root>

            <NavigationMenu.Root className="relative z-[1] flex justify-center rounded-none">
              <NavigationMenu.List className="flex">
                <NavItemSingleColumn
                  id="resources"
                  title={resourcesButtonContent.navTitle}
                  isSelected={resourcesButtonContent.isSelected}
                  items={resourcesButtonContent.items}
                />

                <NavItemSingleColumn
                  id="company"
                  title={companyButtonContent.navTitle}
                  isSelected={companyButtonContent.isSelected}
                  items={companyButtonContent.items}
                />
              </NavigationMenu.List>

              <div className="perspective-[2000px] absolute top-full left-0 flex w-full justify-center">
                <NavigationMenu.Viewport className="relative h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center]  bg-white  sm:w-[var(--radix-navigation-menu-viewport-width)] rounded-none" />
              </div>
            </NavigationMenu.Root>

            <LocaleSelector
              className="mx-12 font-bold"
              overridePath={overridePath}
            />
            <a
              className="btn-primary !px-8 !py-2"
              href="https://meetings.hubspot.com/info3139"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('demo')}
            </a>
          </div>
        </div>
      </div>
      <div className="relative lg:hidden">
        {isOpened && <MobileMenu color={color} overridePath={overridePath} />}
      </div>
    </header>
  );
}

function NavItemMultiColumn({
  id,
  title,
  isSelected,
  firstColumn,
  secondColumn,
  overview,
}: {
  id?: string;
  title: string;
  isSelected: boolean;
  firstColumn: Column;
  secondColumn: Column;
  overview: {
    title: string;
    description: string;
    buttonTitle: string;
    buttonLink: string;
    isSelected: boolean;
  };
}) {
  return (
    <NavigationMenu.Item value={id}>
      <NavigationMenu.Trigger
        className={cx(
          'py-8 px-7 font-bold hover:text-primary',
          isSelected && 'text-primary'
        )}
      >
        {title}
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className="w-[1000px] border border-t-0 rounded-none">
        <div className="grid p-8 grid-cols-4">
          <div className="col-span-2 flex flex-col">
            <span
              className={cx(
                'mb-6 text-xl uppercase font-bold',
                overview.isSelected && 'text-primary'
              )}
            >
              {overview.title}
            </span>
            <div>
              <p className={cx('max-w-xs font-light text-gray-800')}>
                {overview.description}
              </p>
              <Link href={overview.buttonLink} passHref>
                <a
                  className={cx(
                    'mt-4',
                    overview.isSelected ? 'btn-primary' : 'btn-secondary'
                  )}
                >
                  {overview.buttonTitle}
                </a>
              </Link>
            </div>
          </div>

          {[firstColumn, secondColumn]
            .filter((c) => !!c)
            .map((column) => (
              <ul className="flex flex-col gap-2" key={column.link}>
                <NavItemLink
                  isHeaderLink
                  isSelected={column.isSelected}
                  title={column.title}
                  link={column.link}
                />

                {column.items.map((item) => (
                  <li key={item.id}>
                    <NavItemLink
                      isSelected={item.isSelected}
                      title={item.title}
                      link={item.link}
                    />
                  </li>
                ))}
              </ul>
            ))}
        </div>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
}

function NavItemSingleColumn({
  id,
  title,
  isSelected,
  items,
}: {
  id?: string;
  title: string;
  isSelected: boolean;
  items: {
    title: string;
    link: string;
    id: string;
    isSelected: boolean;
  }[];
}) {
  return (
    <NavigationMenu.Item value={id}>
      <NavigationMenu.Trigger
        className={cx(
          'py-8 px-7 font-bold hover:text-primary',
          isSelected && 'text-primary'
        )}
      >
        {title}
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className="border border-t-0 rounded-none">
        <div className="grid grid-cols-1 p-8 w-[200px]">
          <ul className="flex flex-col gap-2">
            {items.map((item) => (
              <li key={item.id}>
                <NavItemLink
                  isSelected={item.isSelected}
                  title={item.title}
                  link={item.link}
                />
              </li>
            ))}
          </ul>
        </div>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
}

function NavItemLink({
  isSelected,
  isHeaderLink = false,
  title,
  link,
}: {
  isHeaderLink?: boolean;
  isSelected: boolean;
  title: string;
  link: string;
}) {
  return (
    <Link href={link} passHref>
      <a
        className={cx(
          'text-lg  hover:text-primary-dark leading-[5px]',
          isSelected && 'text-primary',
          isHeaderLink ? 'uppercase font-bold mb-6' : 'font-normal'
        )}
      >
        {title}
      </a>
    </Link>
  );
}

function MobileMenu({ color, overridePath }: MenuProps) {
  const router = useRouter();
  const { t } = useTranslation();

  const {
    platformButtonContent,
    solutionsButtonContent,
    resourcesButtonContent,
    companyButtonContent,
  } = getLinks({
    router,
    t,
  });

  const headerColumns = [
    ...[platformButtonContent, solutionsButtonContent].map((button) => ({
      id: button.navTitle,
      title: button.navTitle,
      items: [
        {
          title: button.firstColumn.title,
          href: button.firstColumn.link,
        },
        {
          title: button.secondColumn.title,
          href: button.secondColumn.link,
        },
      ],
    })),

    ...[resourcesButtonContent, companyButtonContent].map((button) => ({
      id: button.navTitle,
      title: button.navTitle,
      items: button.items.map(({ title, link }) => ({
        title,
        href: link,
      })),
    })),
  ];

  return (
    <div
      className={cx(
        'absolute inset-x-0 top-[-2px] px-4 pb-4 shadow-lg',
        color === 'dark' && 'bg-white',
        color === 'light' && 'bg-secondary'
      )}
    >
      <nav>
        <ul>
          {headerColumns.map((column) => (
            <li key={column.id}>
              <div className="mb-4 flex flex-col items-center">
                <span className="text-lg font-bold">{t(column.title)}</span>
                <ul className="mt-2 flex w-full flex-col items-center">
                  {column.items.map((item) => (
                    <li key={item.href}>
                      <Link href={item.href}>
                        <a className="block">{t(item.title)}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}

          <li>
            <LocaleSelector
              className="flex justify-center p-4 font-bold hover:text-primary"
              overridePath={overridePath}
            />
          </li>
        </ul>
      </nav>
      <div className="m-4 flex justify-center">
        <a
          className="btn-primary"
          href="https://meetings.hubspot.com/info3139"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('demo')}
        </a>
      </div>
    </div>
  );
}
